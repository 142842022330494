import React, { useState, useEffect } from 'react';
import {
    Container, Row, InputGroup, InputGroupText, Form, FormText, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Label, Input
} from 'reactstrap';

import HeaderComponent from '../header/HeaderComponent';
import axios from 'axios';

import { useLocation } from "react-router-dom"
const moment = require('moment');

const UserInputPage = ({ event_code, user, signOut }) => {

    //console.log("userinput")

    useEffect(() => {

        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };

        setData(prevData => ({
            ...prevData,
            ["email"]: decodeJwtResponse(user.signInUserSession.idToken.jwtToken).email,
            ["token"]: user.signInUserSession.idToken.jwtToken,




        }));


    }, []);

    const [data, setData] = useState({
        token: "",
        name: "",
        seq: "",
        company: "",
        email: "",

    });
    const onValueChanged = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleClick = async () => {

        try {
            const response = await axios({
                method: 'Put',
                url: `${process.env.REACT_APP_API_PATH}/user`,
                data: {
                    name: data.name,
                    company: data.company,
                    seq: event_code,

                },
                headers: {
                    Authorization: `Bearer ${data.token}`
                },

            });

            window.location = `/${event_code}`



        }
        catch (e) {
            console.log(e);
        }

    }
    return (
        <React.Fragment>
            <HeaderComponent signOut={signOut} />
            <div className="main_contents" >
                <Container fluid>
                    <Row>
                        <div className="x_panel" style={{ justifyContent: "center" }}>
                            <InputGroup>
                                <InputGroupText>
                                    이름
                                </InputGroupText>
                                <Input
                                    value={data.name}
                                    onChange={e => onValueChanged("name", e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupText>
                                    회사/학교
                                </InputGroupText>
                                <Input
                                    value={data.company}
                                    onChange={e => onValueChanged("company", e.target.value)}
                                />
                            </InputGroup>

                        </div>
                    </Row>
                    <Row>
                        <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <Button color="primary" onClick={handleClick}>입력</Button>
                        </div>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default UserInputPage;