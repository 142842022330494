import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter, Routes, Route,
  useParams
} from "react-router-dom";
import MainPage from './comps/pages/MainPage';
import Eventmaker from './comps/pages/Eventmaker';
import QRCodePage from './comps/pages/QRCodePage';
import HeaderComponent from './comps/header/HeaderComponent';
import UserInputPage from './comps/pages/UserInputPage';


import { withAuthenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./comps/auth/auth/awsconfig";
import axios from 'axios';
const { components, federated, formFields, services } = require('./comps/auth/theme/settings');
Amplify.configure(awsconfig);

function App(props) {
  const code = localStorage.getItem("event_code")
  const [data, setData] = useState({

    isLoading: true,
    no_user: false,
    seq: "",


  });



  useEffect(() => {

    // console.log("code:", code);



    async function fetchAndSetUser() {



      initiateRulet(props.user.signInUserSession.idToken.jwtToken)
    }
    fetchAndSetUser()
  }, []);
  const initiateRulet = async (_userToken, seq) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_PATH}/user`,
        params: {

        },
        headers: {
          Authorization: `Bearer ${_userToken}`
        },

      });
      if (!response.data.user_info) {
        setData(prevData => ({
          ...prevData,
          ["no_user"]: true,


        }));

      }
      else {
        setData(prevData => ({
          ...prevData,
          ["isLoading"]: false,


        }));
      }

    }
    catch (e) {
      if (e.response.data.result == "invalid_seq") {
        alert("올바르지 않은 이벤트입니다.")
      }
      if (e.response.data.result == "already_exist") {
        alert("이미 참여하신 이벤트입니다.")
      }
    }
  }
  if (data.no_user) {
    return (
      <UserInputPage user={props.user} signOut={props.signOut} event_code={code} />)

  }

  if (data.isLoading) {
    return (
      <div>
        <HeaderComponent />
        <div className="loading-overlay"></div>
      </div>)
  }


  return (
    <MainPage user={props.user} signOut={props.signOut} event_code={code} />
  )

}



export default withAuthenticator(App, {
  hideSignUp: false,
  socialProviders: ["google"],
  federated: federated,
  services: services,

  formFields: formFields,
  components: components
});
