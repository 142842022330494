import React, { useRef, useState, useEffect } from 'react';
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import {
    Container, Row, Col
} from 'reactstrap';

import {

    useParams
} from "react-router-dom";
import HeaderComponent from '../header/HeaderComponent';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const moment = require("moment")
const MainPage = ({ event_code, user, signOut }) => {

    const onValueChanged = (code, value) => {
        setData(prevData => ({
            ...prevData,
            [code]: value
        }));
    };

    // const onValueChanged = (code, value) => {
    //     setData(prevData => ({
    //         ...prevData,
    //         [code]: value
    //     }));
    // };
    const [data, setData] = useState({
        userToken: '',
        isLoading: false,
        token: "",
        animationFinished: false,
        selectedValue: -1,
        selectedItem: { test: "test" },
        eventInfo: {},
        isSpinning: false,
        seq: '',
        total: 0,
        initiated: false,
        fruits: [
            { name: 'Loading', area: 100, color: "#cfe0e8" },

        ],


    });
    // const [animationFinished, setanimationFinished] = useState(false);
    // const [selectedValue, setSelectedValue] = useState(-1);
    // const [selectedItem, setSelectedItem] = useState({ test: "test" });
    // const [eventInfo, setEventInfo] = useState({});
    // const [isSpinning, setIsSpinning] = useState(false);
    // const [seq, setSeq] = useState("W4s6QVoxMyJd2U8");
    // const [fruits, setFruits] = useState([
    //     { name: 'Loading', area: 100, color: "#cfe0e8" },

    // ]);
    // const [userToken, setUserToken] = useState("");
    // const [isLoading, setIsLoading] = useState(false);




    useEffect(() => {
        //console.log("code:", event_code)

        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };

        onValueChanged("email", decodeJwtResponse(user.signInUserSession.idToken.jwtToken).email);


        let mycode = event_code
        if (mycode == undefined || mycode == "") {
            if (!localStorage.getItem('event_seq')) {
                alert("올바르지 않은 접근입니다.");
                return;
            }
            mycode = localStorage.getItem('event_code');
        }
        setData(prevData => ({
            ...prevData,
            ["email"]: decodeJwtResponse(user.signInUserSession.idToken.jwtToken).email,
            ["token"]: user.signInUserSession.idToken.jwtToken,
            ["code"]: mycode,


        }));

        let websocket = undefined;
        let timer = undefined
        function closeWebSocket() {

            if (timer) {
                clearInterval(this.timer);
                timer = null;
            }
            if (websocket) {
                websocket.close();
                websocket = null;
            }
        }
        function connectToWebScoket(userToken) {
            const address = `${process.env.REACT_APP_SOCKET_API_PATH}?token=${userToken}`
            websocket = new WebSocket(address);
            websocket.onopen = () => {
                timer = setInterval(() => {
                    websocket.send(JSON.stringify({ message: 'ping' }));
                }, 60 * 1000);
            };
            websocket.onmessage = (message) => {
                let obj = JSON.parse(message.data);
                onMessageReceived(obj);
            };

            websocket.onclose = (event) => {
                //console.log('onclose');
                if (timer || websocket) closeWebSocket();
            };

            websocket.onerror = (event) => {
                console.error('WebSocket error observed:', event);
                if (timer || websocket) closeWebSocket();
            };
        }
        async function fetchAndSetUser() {

            //console.log(user);

            connectToWebScoket(user.signInUserSession.idToken.jwtToken);
            initiateRulet(user.signInUserSession.idToken.jwtToken, mycode)
        }
        fetchAndSetUser()
    }, []);


    //  console.log();
    //console.log(user);


    const wheelRef = useRef(null);
    const needleRef = useRef(null);

    const getRandomInteger = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const onMessageReceived = (data) => {
        if (data.connectionId) {
            return;
        }
        onValueChanged("isLoading", false);
        const dataObject = (data);
        if (data.error) {
            if (data.predefinedError == "invalid_seq") {
                alert("올바르지 않은 이벤트입니다.")
            }
            if (data.predefinedError == "already_exist") {
                alert("이미 참여하신 이벤트입니다.")
            }
            if (data.predefinedError == "max_reached") {
                alert("마감되었습니다.")
            }
            if (data.predefinedError == "invalid_code") {
                alert("올바르지 않거나 이미 참여하신 코드입니다.")
            }
        }
        else {
            const rand = dataObject.selected_value;
            //console.log(response.data)
            setData(prevData => ({
                ...prevData,
                ["selectedItem"]: dataObject.selected_item,
                ["selectedValue"]: rand,
                ["total"]: dataObject.total
            }));

            // setSelectedItem(dataObject.selected_item);
            // setSelectedValue(rand)
        }
    }
    const handleClick = async () => {
        if (data.fruits[0].name == "Loading") {
            alert("아직 로딩중입니다.");
            return;
        }
        onValueChanged("isLoading", true);

        try {
            const response = await axios({
                method: 'Post',
                url: `${process.env.REACT_APP_API_PATH}/ruletv2/code/redeem`,
                data: {

                    code: data.code,
                },
                headers: {
                    Authorization: `Bearer ${data.token}`
                },

            });

        }
        catch (e) {
            if (e.response.data.result == "invalid_seq") {
                alert("올바르지 않은 이벤트입니다.")
            }
            if (e.response.data.result == "already_exist") {
                alert("이미 참여하신 이벤트입니다.")
            }
            if (e.response.data.result == "max_reached") {
                alert("마감되었습니다.")
            }
        }
    }
    useEffect(() => {
        if (!data.isSpinning && data.selectedValue != -1) {
            onValueChanged("isSpinning", true);
            animateWheel();
            animateNeedle(data.selectedValue, data.total);
        }
    }, [data.selectedValue]);
    const animateWheel = () => {
        const wheelElement = wheelRef.current;
        if (wheelElement) {
            wheelElement.classList.add('spinning');
        }
    }

    const animateNeedle = (randValue, total) => {

        let stoppingPoint = 1000 + (randValue / total * 100)
        const needleElement = needleRef.current;
        const wheelElement = wheelRef.current;
        if (needleElement) {
            const startingAngle = 0;
            const stoppingAngle = (360 * stoppingPoint) / 100;
            const cubic_bezier = "cubic-bezier(0.0, 0.0, 0.18, 1.0)"
            const duration = 3000;

            wheelElement.style.transform = `translate(-50%, -100%) rotate(${startingAngle}deg)`;
            wheelElement.animate(
                [{ transform: `rotate(${startingAngle}deg)` },
                { transform: `rotate(${stoppingAngle}deg)` }],
                {
                    duration: duration,
                    easing: cubic_bezier,
                    fill: 'forwards'
                }
            );

            needleElement.style.transform = `translate(-50%, -100%) rotate(${startingAngle}deg)`;
            const animation = needleElement.animate(
                [{ transform: `translate(-50%, -100%) rotate(${startingAngle}deg)` },
                { transform: `translate(-50%, -100%) rotate(${stoppingAngle}deg)` }],
                {
                    duration: duration,
                    easing: cubic_bezier,
                    fill: 'forwards'
                }
            );
            //console.log(data.selectedItem);

            animation.onfinish = () => {
                // setSelectedValue(randValue)
                // setIsSpinning(false);


                if (data.selectedItem.id != "no") {
                    alert(`축하합니다.${data.selectedItem.name} 에 당첨되었습니다.링크드인에 등록된 이메일로 연락드릴게요!`);
                }
                else {
                    alert(`죄송합니다...다음에 또 참여해주세요!`);
                }
                setData(prevData => ({
                    ...prevData,
                    ["selectedValue"]: randValue,
                    ["isSpinning"]: false,
                    ["animationFinished"]: true
                }));

            };
        }
    }

    useEffect(() => {
        const wheelElement = wheelRef.current;
        if (wheelElement) {
            wheelElement.addEventListener('animationend', handleAnimationEnd);
        }
        return () => {
            if (wheelElement) {
                wheelElement.removeEventListener('animationend', handleAnimationEnd);
            }
        };
    }, []);

    const handleAnimationEnd = () => {
        const wheelElement = wheelRef.current;
        if (wheelElement) {
            wheelElement.classList.remove('spinning');
        }
    }

    const getGradient = () => {
        let text = "";
        let total = 0;
        for (const item of data.fruits) {
            total += item.area;
        }
        let current = 0;
        let textList = [];
        for (const item of data.fruits) {
            textList.push({
                angle: (current * 2 + (item.area / total) * 360) / 2,
                text: item.name,
                rate: (item.area / total * 100).toFixed(1)
            })
            current += (item.area / total) * 360;
            text += `${item.color} 0 ${current}deg ,`;
        }
        return { background: text.substring(0, text.length - 1), text: textList };
    }

    const { background, text } = getGradient(data.userToken);
    const initiateRulet = async (_userToken, code) => {
        try {
            try {
                const response = await axios({
                    method: 'Get',
                    url: `${process.env.REACT_APP_API_PATH}/ruletv2/code/redeem`,
                    params: {
                        type: "data",
                        code: code,

                    },
                    headers: {
                        Authorization: `Bearer ${_userToken}`
                    },

                });

                // if (response.data.event_info.current >= response.data.event_info.max) {
                //     alert("마감되었습니다.")
                //     return;

                // }

                setData(prevData => ({
                    ...prevData,
                    ["code"]: response.data.code,
                    ["fruits"]: response.data.items,
                    ["eventInfo"]: response.data.event_info,
                    ["initiated"]: true,


                }));
            } catch (e) {
                // console.log(e);
                alert("올바르지 않은 코드이거나 이미 참여하였습니다.")
            }

            // setFruits(response.data.items)
            // setEventInfo(response.data.event_info)
        }
        catch (e) {
            if (e.response.data.result == "invalid_seq") {
                alert("올바르지 않은 이벤트입니다.")
            }
            if (e.response.data.result == "already_exist") {
                alert("이미 참여하신 이벤트입니다.")
            }
        }
    }

    if (!data.initiated) {
        return (<HeaderComponent signOut={signOut} />)
    }

    return (
        <div >
            <HeaderComponent signOut={signOut} />
            <div className="main_contents" >
                <Container fluid>
                    {data.isLoading && <div className="loading-overlay"></div>}
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <div><b>참여하시면 이메일 및 입력정보를 수집합니다.(스팸 절대 안보내요. 관리용으로만 사용합니다.) </b></div>
                            <div><b> 비정상적인 참여 정황이 포착되면 당첨은 취소됩니다. </b></div>

                            <div>
                                <ul className="list-unstyled" style={{
                                    "list-style-type": "disc",
                                    "padding-left": 20
                                }}>
                                    <li><strong>코드명:</strong> {`${data.code}`}</li>
                                    <li><strong>이벤트 이름:</strong> {`${data.eventInfo.name}(${data.eventInfo.type})`}</li>
                                    <li><strong className="font-weight-700">Created:</strong> {`${moment(data.eventInfo.event_datetime).format("YYYY-MM-DD HH:mm")}`}</li>


                                </ul>

                            </div>

                            {/* 확률:{text.map((text, index) => (

                                <text>{`[${text.text}:${text.rate}%]`}</text>

                            ))} */}

                        </Col>
                    </Row>
                    <Row>
                        <div>
                            <div className={`spinning-wheel ${data.isSpinning ? 'spin' : ''}`} onClick={handleClick}
                                style={{
                                    "backgroundImage": `conic-gradient(${background})`
                                }}
                            >
                                {text.map((text, index) => (
                                    <div key={index} className="hour-marking" style={{
                                        transform: `rotate(${text.angle}deg)`
                                    }}>
                                        <div className="hour-number" style={{
                                            textAlign: "center",
                                            transform: `rotate(${360 - text.angle}deg)`
                                        }}>
                                            <div>{text.text}</div><div>({text.rate}%)</div>
                                        </div>
                                    </div>
                                ))}
                                <div ref={wheelRef} className={`wheel ${data.isSpinning ? 'spinning' : ''}`}></div>
                                <div ref={needleRef} className={`needle ${data.isSpinning ? 'rotating' : ''}`}></div>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <Col md={12} style={{ textAlign: "center", marginTop: 15 }}>
                            {data.animationFinished ? `뽑은 값은 [${data.selectedValue}]입니다!` : "룰렛을 클릭하세요!"}


                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default MainPage;