import React, { useState, useEffect } from 'react';
import {
    Container, Row, InputGroup, InputGroupText, Form, FormText, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Label, Input
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderComponent from '../header/HeaderComponent';
import axios from 'axios';
import QRCode from "react-qr-code";
const moment = require('moment');

const Eventmaker = ({ event_seq, user, signOut }) => {
    const [data, setData] = useState({
        event_name: '',
        max: '10',
        no: '44',
        no2: '40',
        candy: '10',
        coffee: '5',
        weight: 0,
        api_key: '',
        keycap: 1,

        event_type: 'offline',
        token: "",
        email: "",

    });
    useEffect(() => {

        function decodeJwtResponse(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        };

        setData(prevData => ({
            ...prevData,
            ["email"]: decodeJwtResponse(user.signInUserSession.idToken.jwtToken).email,
            ["token"]: user.signInUserSession.idToken.jwtToken,



        }));


    }, []);


    const onValueChanged = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleClick = async () => {
        let rate = [
            { id: "no", name: '꽝ㅠㅠ', area: parseInt(data.no), color: "#cfe0e8" },
            { id: "keycap", name: '키캡', area: parseInt(data.keycap), color: "#daebe8" },
            { id: "no", name: '꽝ㅠㅠ', area: parseInt(data.no2), color: "#cfe0e8" },
            { id: "candy", name: '츄파츕스+새콤달콤', area: parseInt(data.candy), color: "#b7d7e8" },
            { id: "coffee", name: '커피쿠폰', area: parseInt(data.coffee), color: "#87bdd8" },
        ]
        try {
            const response = await axios({
                method: 'Put',
                url: `${process.env.REACT_APP_API_PATH}/event`,
                data: {
                    name: data.event_name,
                    datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
                    weight: data.weight,
                    type: data.event_type,
                    seq: data.seq,
                    max: parseInt(data.max),
                    rate: JSON.stringify(rate)
                },
                headers: {
                    Authorization: `Bearer ${data.token}`
                },

            });
            const seq = response.data.seq;
            localStorage.setItem("current_seq", seq);
            window.location = "/qr"



        }
        catch (e) {
            if (e.response.data.result == "invalid_seq") {
                alert("올바르지 않은 이벤트입니다.")
            }
            if (e.response.data.result == "already_exist") {
                alert("이미 참여하신 이벤트입니다.")
            }
            if (e.response.data.result == "max_reached") {
                alert("마감되었습니다.")
            }
        }
    }
    return (
        <React.Fragment>
            <HeaderComponent signOut={signOut} />
            <div className="main_contents" >
                <Container fluid>
                    <Row>
                        <div className="x_panel" style={{ justifyContent: "center" }}>
                            <InputGroup>
                                <InputGroupText>
                                    이벤트명
                                </InputGroupText>
                                <Input
                                    value={data.event_name}
                                    onChange={e => onValueChanged("event_name", e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupText>
                                    max
                                </InputGroupText>
                                <Input
                                    value={data.max}
                                    onChange={e => onValueChanged("max", e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupText>
                                    weight
                                </InputGroupText>
                                <Input
                                    value={data.weight}
                                    onChange={e => onValueChanged("weight", e.target.value)}
                                />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupText>
                                    꽝 확률
                                </InputGroupText>
                                <Input
                                    value={data.no}
                                    onChange={e => onValueChanged("no", e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupText>
                                    꽝 확률2
                                </InputGroupText>
                                <Input
                                    value={data.no2}
                                    onChange={e => onValueChanged("no2", e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupText>
                                    키캡
                                </InputGroupText>
                                <Input
                                    value={data.keycap}
                                    onChange={e => onValueChanged("keycap", e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupText>
                                    츄파츕스확률
                                </InputGroupText>
                                <Input
                                    value={data.candy}
                                    onChange={e => onValueChanged("candy", e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupText>
                                    커피쿠폰 확률
                                </InputGroupText>
                                <Input
                                    value={data.coffee}
                                    onChange={e => onValueChanged("coffee", e.target.value)}
                                />
                            </InputGroup>
                            <FormGroup row>
                                <Label for="event_type" sm={2}>Event Type</Label>

                                <Input
                                    id="event_type"
                                    name="event_type"
                                    type="select"
                                    value={data.event_type}
                                    onChange={e => onValueChanged("event_type", e.target.value)}
                                >
                                    <option value="online">
                                        온라인
                                    </option>
                                    <option value="offline">
                                        오프라인
                                    </option>

                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <Button color="primary" onClick={handleClick}>생성</Button>
                        </div>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Eventmaker;