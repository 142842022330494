import React, { useState, useEffect } from 'react';
import {
    Container, Row, InputGroup, InputGroupText, Form, FormText, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Label, Input
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderComponent from '../header/HeaderComponent';
import axios from 'axios';
import QRCode from "react-qr-code";
const moment = require('moment');

const QRCodePage = () => {

    useEffect(() => {

        onValueChanged("seq", localStorage.getItem("current_seq"))
    }, []);

    const [data, setData] = useState({
        seq: '',
        url: '',
    });
    const onValueChanged = (name, value) => {
        if (name == "url") {
            let ar = value.split("=")
            if (ar.length > 1) {
                value = value.split("=")[1];
            }
        }
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <React.Fragment>
            <HeaderComponent />
            <div className="main_contents" >
                <Container fluid>
                    <Row>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`https://qr.rubywave.io?seq=${data.url == '' ? data.seq : data.url}`}
                            viewBox={`0 0 256 256`}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ textAlign: "center", marginTop: 15 }}>
                                <a href={`https://qr.rubywave.io?seq=${data.url == '' ? data.seq : data.url}`}>   {`https://qr.rubywave.io?seq=${data.url == '' ? data.seq : data.url}`}</a>
                            </div>
                        </Col>
                    </Row>
                    <InputGroup>
                        <InputGroupText>
                            url
                        </InputGroupText>
                        <Input
                            value={data.coffee}
                            onChange={e => onValueChanged("url", e.target.value)}
                        />
                    </InputGroup>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default QRCodePage;