import React, { Component } from 'react';

import { Map, List, Record } from 'immutable';

import {
    Input, Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import logo from "../../images/aws_classrom_white_all.png";


const HeaderComponent = (props) => {

    return (

        <Navbar color="faded" light expand="md" className="navbar  navbar-expand-lg navbar-dark nav_menu">

            <img src={logo} className="header_logo" alt="Logo" style={{ "marginRight": "10px" }} />
            <NavbarBrand href="/" style={{ fontWeight: 800 }}>
                반가워요 룰렛
            </NavbarBrand>
            <Nav className="mr-auto" navbar>





            </Nav>
            <Nav navbar>

                <NavItem className="d-flex align-items-center">
                    <NavLink className="font-weight-bold" href="#" onClick={props.signOut}>로그 아웃</NavLink>
                </NavItem>


            </Nav>
        </Navbar>


    );
}


export default HeaderComponent;
